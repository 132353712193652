@import '../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "variables/global.scss";

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    display: flex;
    min-height: 100%;
    background-color: #f8f8f8;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: $text-color;
    font-size: $medium-text-size;
}